import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import DropDownMenu from "../../components/DropDownMenu/DropDownMenu";
import { StyledTextFieldResponsive } from "../StyledTextField";
import labels from "../Labels.json";
import "./Annotation.scss";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const Annotation = (props) => {
  const [supStdInput, setSupStdInput] = useState<string>(
    props.annotation.customAno ? "" : props.annotation.superClass
  );
  const [subStdInput, setSubStdInput] = useState<string>(
    props.annotation.customAno ? "" : props.annotation.subClass
  );
  const [diaStdInput, setDiaStdInput] = useState<string>(
    props.annotation.customAno ? "" : props.annotation.diagnosis
  );

  const [supCustomInput, setSupCustomInput] = useState<string>(
    props.annotation.superClass
  );
  const [subCustomInput, setSubCustomInput] = useState<string>(
    props.annotation.subClass
  );
  const [diaCustomInput, setDiaCustomInput] = useState<string>(
    props.annotation.diagnosis
  );

  const [superclassIndex, setSuperclassIndex] = useState<number>(
    props.annotation.indexes[0]
  );
  const [subclassIndex, setSubclassIndex] = useState<number>(
    props.annotation.indexes[1]
  );
  const [diagnosisIndex, setDiagnosisIndex] = useState<number>(
    props.annotation.indexes[2]
  );

  const [customAno, setCustomAno] = useState<boolean>(
    props.annotation.customAno
  );

  const SetPropValues = () => {
    setSupStdInput(props.annotation.superClass);
    setSubStdInput(props.annotation.subClass);
    setDiaStdInput(props.annotation.diagnosis);
    setSupCustomInput(props.annotation.superClass);
    setSubCustomInput(props.annotation.subClass);
    setDiaCustomInput(props.annotation.diagnosis);
    setCustomAno(props.annotation.customAno);
  };

  useEffect(() => {
    UpdateValues(
      props.annotation.superClass === ""
        ? Object.keys(labels)[superclassIndex]
        : props.annotation.superClass,
      "supStd"
    );
  }, []);

  useEffect(() => {
    SetPropValues();
  }, [props]);

  const ChangeValue = (type: number, index: number) => {
    if (type === 0 || type === 1) {
      if (type === 0) setSubclassIndex(0);
      setDiagnosisIndex(0);
    }

    switch (type) {
      case 0:
        setSuperclassIndex(index);
        break;
      case 1:
        setSubclassIndex(index);
        break;
      case 2:
        setDiagnosisIndex(index);
        break;
    }
  };

  const UpdateValues = async (
    value: string = "",
    inputField: string = "",
    _customAno: boolean = customAno
  ) => {
    let values: string[];

    switch (inputField) {
      case "supStd":
        setSupStdInput(value);
        values = [value, subStdInput, diaStdInput];
        break;
      case "subStd":
        setSubStdInput(value);
        values = [supStdInput, value, diaStdInput];
        break;
      case "diagStd":
        setDiaStdInput(value);
        values = [supStdInput, subStdInput, value];
        break;
      case "supCustom":
        setSupCustomInput(value);
        values = [value, subCustomInput, diaCustomInput];
        break;
      case "subCustom":
        setSubCustomInput(value);
        values = [supCustomInput, value, diaCustomInput];
        break;
      case "diagCustom":
        setDiaCustomInput(value);
        values = [supCustomInput, subCustomInput, value];
        break;
      default:
        values = _customAno
          ? [supCustomInput, subCustomInput, diaCustomInput]
          : [supStdInput, subStdInput, diaStdInput];
        break;
    }

    props.updateValues(
      props.i,
      ...values,
      [superclassIndex, subclassIndex, diagnosisIndex],
      _customAno
    );

    setCustomAno(_customAno);
  };

  return (
    <Box className="Anno-con">
      <DropDownMenu
        className="Anno-ddmPriority"
        label="Priority"
        currentElement={props.annotation.priority}
        elements={props.priorityElements}
        passedFunction={(newPriority) =>
          props.changePriority(props.annotation.priority, newPriority + 1)
        }
        smallscreen={props.winWidth <= 1000 ? "true" : "false"}
        hasvalue="true"
        emptyFirst={false}
        variant="standard"
        size="small"
      />
      {!customAno ? (
        <Box className="AP-inputCon">
          <DropDownMenu
            className="AP-ddm"
            label="Superclass"
            currentElement={supStdInput}
            elements={Object.keys(labels)}
            passedFunction={(i) => ChangeValue(0, i)}
            hasvalue={supStdInput === "" ? "false" : "true"}
            smallscreen={props.winWidth <= 1000 ? "true" : "false"}
            onChange={(e) => {
              UpdateValues(e.target.value, "supStd");
            }}
            emptyFirst={false}
          />
          <DropDownMenu
            className="AP-ddm"
            label="Subclass"
            currentElement={subStdInput}
            elements={Object.keys(Object.values(labels)[superclassIndex])}
            passedFunction={(i) => ChangeValue(1, i)}
            emptyFirst
            hasvalue={subStdInput === "" ? "false" : "true"}
            smallscreen={props.winWidth <= 1000 ? "true" : "false"}
            onChange={(e) => {
              UpdateValues(e.target.value, "subStd");
            }}
          />
          <DropDownMenu
            className="AP-ddm AP-ddmLong"
            label="Diagnosis"
            currentElement={diaStdInput}
            elements={
              subclassIndex === 0
                ? []
                : Object.values(
                    Object.values(Object.values(labels)[superclassIndex])[
                      subclassIndex - 1
                    ]
                  )
            }
            passedFunction={(i) => ChangeValue(2, i)}
            emptyFirst
            hasvalue={diaStdInput === "" ? "false" : "true"}
            smallscreen={props.winWidth <= 1000 ? "true" : "false"}
            onChange={(e) => {
              UpdateValues(e.target.value, "diagStd");
            }}
          />
        </Box>
      ) : (
        <Box className="AP-inputCon">
          <StyledTextFieldResponsive
            className="AP-ddm"
            variant="standard"
            autoComplete="disabled"
            label="Superclass"
            name="superclass"
            hasvalue={supCustomInput === "" ? "false" : "true"}
            smallscreen={props.winWidth <= 1000 ? "true" : "false"}
            onChange={(e) => {
              UpdateValues(e.target.value, "supCustom");
            }}
            value={supCustomInput}
          />
          <StyledTextFieldResponsive
            className="AP-ddm"
            variant="standard"
            autoComplete="disabled"
            label="Subclass"
            name="subclass"
            hasvalue={subCustomInput === "" ? "false" : "true"}
            smallscreen={props.winWidth <= 1000 ? "true" : "false"}
            onChange={(e) => {
              UpdateValues(e.target.value, "subCustom");
            }}
            value={subCustomInput}
          />
          <StyledTextFieldResponsive
            className="AP-ddmLong"
            variant="standard"
            autoComplete="disabled"
            label="Diagnosis"
            name="diagnosis"
            hasvalue={diaCustomInput === "" ? "false" : "true"}
            smallscreen={props.winWidth <= 1000 ? "true" : "false"}
            onChange={(e) => {
              UpdateValues(e.target.value, "diagCustom");
            }}
            value={diaCustomInput}
          />
        </Box>
      )}
      <Button
        className="AP-customAnoBtn"
        variant="outlined"
        onClick={() => UpdateValues("", "", !customAno)}
        startIcon={customAno ? <FormatListBulletedIcon /> : <EditIcon />}
      >
        {customAno ? "Standard annotation" : "Custom annotation"}
      </Button>
      {props.i != 0 && (
        <Button
          className="Anno-CloseBtn"
          onClick={() => props.deleteAnno(props.i)}
        >
          <CloseIcon />
        </Button>
      )}
    </Box>
  );
};

export default Annotation;
