import { styled } from "@mui/material/styles";
import { TextField } from '@mui/material'


type InputProps = {
    hasvalue: string,
    smallscreen: string
}

export const StyledTextFieldResponsive = styled(TextField)<InputProps>(({ hasvalue, smallscreen }) => ({
    "& label": {
        color: "#323232",
        fontSize: smallscreen === "true" ? "0.9vw" : "14px",
        marginTop: hasvalue === "true" && smallscreen === "true" ? "2.9vh" : "0px"
    },
    "& label.Mui-focused": {
        color: "#439ea7",
        marginTop: smallscreen === "true" ? "2.9vh" : "0px"
    },
    "& input": {
        fontSize: smallscreen === "true" ? "1vw" : "14px",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#439ea7"
    }
}));

export const StyledTextField = styled(TextField)(({
    "& label": {
        color: "#323232"
    },
    "& label.Mui-focused": {
        color: "#439ea7"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#439ea7"
    }
}));