import { InputLabel, Select, MenuItem } from '@mui/material';
import './DropDownMenu.scss'
import React from 'react';
import StyledFormControl from "../StyledFormControl"

const renderValue = (value) => { return value }

const DropDownMenu = props => (
    <StyledFormControl
        className={props.className}
        variant={props.variant ? props.variant : 'standard'}
        size={props.size ? props.size : 'medium'}
        hasvalue={props.hasvalue}
        smallscreen={props.smallscreen}
    >
        <InputLabel>{props.label}</InputLabel>
        <Select
            className={props.selectStyle ? props.selectStyle : 'DDM-select'}
            value={props.currentElement ? props.currentElement : ""}
            defaultValue=""
            renderValue={() => renderValue(props.currentElement ? props.currentElement : "")}
            label={props.label}
            onChange={props.onChange}
        >
            {props.emptyFirst && props.elements?.length > 0 && <MenuItem key="element_empty" className={props.currentElement === "" ? 'DDM-menuItem DDM-menuItemSelected' : 'DDM-menuItem'} onClick={() => props.passedFunction(0)} value="">
                <p className={props.currentElement === "" ? 'DDM-colorText' : ''} />
            </MenuItem>}
            {props.elements.map((element, index) => {
                const isSelected = props.currentElement === (props.replace ? element.replace(props.replace, "") : element) ? true : false

                return <MenuItem key={"element_" + element} className={isSelected ? 'DDM-menuItem DDM-menuItemSelected' : 'DDM-menuItem'} onClick={() => props.passedFunction(index + (props.emptyFirst ? 1 : 0))} value={element}>
                    <p className={isSelected ? 'DDM-colorText' : ''}>{element}</p>
                </MenuItem>
            })}
        </Select>
    </StyledFormControl >
)

export default DropDownMenu