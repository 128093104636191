import React, { useState, useEffect } from "react"
import { Box, Button } from '@mui/material'
import './LoginPage.scss'
import { useNavigate } from "react-router-dom";
import { StyledTextField } from "../../components/StyledTextField";
import { useToasts } from 'react-toast-notifications';
import Auth from "../../components/Auth";


const LoginPage = props => {
    const [loginError, setLoginError] = useState(null)
    const navigate = useNavigate()
    const { addToast } = useToasts();

    useEffect(() => {
        if (window.innerWidth <= 1000) window.screen.orientation.lock('portrait');
    }, [])

    const Login = async e => {
        e.preventDefault();

        const loginResponse = await props.auth(e.target[0].value, e.target[1].value)

        if (loginResponse.error) {
            addToast('Failed to login (see console for more information)', {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 8000
            });
            console.error(loginResponse)
            setLoginError(loginResponse.message)
        }
        else {
            navigate('/annotation-page')
        }
    }

    return (
        <Box
            className="LP-mainCon"
        >
            <img
                className="LP-lablerLogoImg"
                src={require('../../assets/images/logos/Labler.png')}
                alt="Logo"
            />
            <form
                method="GET"
                onSubmit={Login}
                className="LP-loginPanel"
            >
                <StyledTextField
                    className="LP-inputField"
                    variant="standard"
                    autoComplete="disabled"
                    label="Email"
                    name="email"
                    error={loginError ? true : false}
                />
                <StyledTextField
                    className="LP-inputField"
                    variant="standard"
                    autoComplete="disabled"
                    label="Password"
                    type="password"
                    name="password"
                    helperText={loginError ? "Error: " + loginError : ""}
                    error={loginError ? true : false}
                />
                <Button
                    className="LP-loginBtn"
                    variant="contained"
                    type="submit"
                >
                    Login
                </Button>
            </form>
            <a
                className="LP-hatoLogoImgCon"
                href={'https://hatomedicaltechnologies.com/'}
                target="_blank"
                rel="noreferrer"
            >
                <img
                    className="LP-hatoLogoImg"
                    src={require('../../assets/images/logos/HATO-Logo.png')}
                    alt="Logo"
                />
            </a>
            <Box
                className="LP-bottomGradient"
            />
        </Box>
    )
}


export default LoginPage