import React, { useState } from "react"
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import AnnotationPage from "./pages/AnnotationPage/AnnotationPage";
import { ToastProvider } from 'react-toast-notifications';
import Auth from "./components/Auth";

export default function App() {
    const [user, setUser] = useState(null)

    const ProtectedRoute = ({ user, children }) => {
        if (!user) {
            return <Navigate to="/" replace />;
        }

        return children;
    };

    const CallAuth = async (email: string, password: string) => {
        const response = await Auth(email, password)

        if (!response.error) {
            setUser(response.profile)
        }

        return response
    }


    return (
        <ToastProvider >
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={<LoginPage auth={CallAuth} />}
                    />
                    <Route
                        path="/annotation-page"
                        element={
                            <ProtectedRoute user={user}>
                                <AnnotationPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>Error 404... seems like you've hit an unknown page</p>
                            </main>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </ToastProvider>
    );
}