import { styled } from "@mui/material/styles";
import { FormControl } from '@mui/material'

type InputProps = {
    hasvalue: string,
    smallscreen: string
}

const StyledFormControl = styled(FormControl)<InputProps>(({ hasvalue, smallscreen }) => ({
    "& label": {
        color: "#323232",
        fontSize: smallscreen === "true" ? "0.9vw" : "14px",
        marginTop: hasvalue === "true" && smallscreen === "true" ? "3vh" : "0px"
    },
    "& label.Mui-focused": {
        color: "#439ea7",
        marginTop: smallscreen === "true" ? "3vh" : "0px"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#439ea7"
    }
}));


export default StyledFormControl