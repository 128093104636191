import { json } from "stream/consumers";
import lablerConfig from "../labler-config.json";

const Auth = async (email: string, password: string) => {
  const result = await fetch(`${lablerConfig.host}/api/v1/auth/signin/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then(async (response) => await response.json())
    .catch((err) => console.error("Failed to sign in.. error: " + err));

  if (!result.error) {
    sessionStorage.setItem("token", result.token);
    sessionStorage.setItem("refresh", result.refresh);
    sessionStorage.setItem("profile", JSON.stringify(result.profile));
    sessionStorage.setItem("expires_in", result.expires_in);
  }

  return result;
};

export default Auth;
