import lablerConfig from '../labler-config.json'


const Refresh = async () => {
    const result = await fetch(`${lablerConfig.host}/api/v1/token/refresh/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
            "refresh": sessionStorage.getItem("refresh"),
            "Authorization": "Token " + await sessionStorage.getItem("token"),
        }),
    })
        .then(async response => await response.json())
        .catch(err => console.error("Failed to refresh.. error: " + err))

    if (!result.error) {
        sessionStorage.setItem("token", result.token)
        sessionStorage.setItem("refresh", result.refresh)
        sessionStorage.setItem("profile", JSON.stringify(result.profile))
        sessionStorage.setItem("expires_in", result.expires_in)
    }

    return result
}

export const RefreshTimer = () => {
    setInterval(function () {
        const expires_in = parseInt(sessionStorage.getItem("expires_in") || "0") - 1
        sessionStorage.setItem("expires_in", expires_in.toString())

        if (expires_in <= 0) {
            Refresh()
        }
    }, 1000);
}

export default Refresh