import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, RadioGroup } from "@mui/material";
import "./AnnotationPage.scss";
import lablerConfig from "../../labler-config.json";
import DropDownMenu from "../../components/DropDownMenu/DropDownMenu";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { RefreshTimer } from "../../components/Refresh";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import NumbersIcon from "@mui/icons-material/Numbers";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Annotation from "../../components/Annotation/Annotation";
import AddIcon from "@mui/icons-material/Add";

class AnnotationData {
  superClass: string = "";
  subClass: string = "";
  diagnosis: string = "";
  priority: number = 1;
  indexes: number[] = [0, 0, 0];
  customAno: boolean = false;
}

const AnnotationPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [record, setRecord] = useState<any>();
  const [gridSize, setGridSize] = useState<string>("any");
  const [gridDisplaySize, setGridDisplaySize] = useState<number>(25);
  const [annotations, setAnnotations] = useState<AnnotationData[]>([
    new AnnotationData(),
  ]);

  const navigate = useNavigate();
  const { addToast } = useToasts();
  const undefinedText = "not defined";

  const winWidth = window.innerWidth;
  const gridOptions = ["Any", "25mm", "50mm"];

  useEffect(() => {
    if (window.innerWidth <= 1000) window.screen.orientation.lock("landscape");
    GetRecord();
    RefreshTimer();
  }, []);

  const GetRecord = async (grid = gridSize) => {
    setIsLoading(true);
    const result = await fetch(
      `${lablerConfig.host}/api/v1/record/get/${grid}/`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Token " + (await sessionStorage.getItem("token")),
        },
      }
    )
      .then(async (response) => await response.json())
      .catch((err) => console.error("Failed to get record.. error: " + err));

    if (
      result.detail ===
      ("Invalid Token" || "User is not active" || "The Token is expired")
    ) {
      navigate("/");
    } else if (result.record?.grid_mm_available?.length === 0) {
      GetRecord(gridSize);
      return;
    } else if (!result.error) {
      var _gridDisplaySize = gridDisplaySize;

      if (result.record.grid_mm_available.length === 1) {
        if (result.record.grid_mm_available.includes(25)) {
          _gridDisplaySize = 25;
          setGridDisplaySize(25);
        } else {
          _gridDisplaySize = 50;
          setGridDisplaySize(50);
        }
      }

      result.record.image = HandleGridSize(result.record, _gridDisplaySize);

      setRecord(result.record);

      if (result.record && winWidth >= 1000) {
        addToast("Successfully loaded record", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 4000,
        });
      } else if (!result.record) {
        addToast("All records has been annotated", {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 6000,
        });
      }
    } else {
      addToast("Failed to load record (see console for more information)", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 8000,
      });
    }

    setIsLoading(false);
  };

  const HandleGridSize = (_record, _gridSize) => {
    var url;
    var _gridSizeInt;

    _record.images.forEach((image) => {
      if (image.grid_mm === _gridSize) {
        _gridSizeInt = image.grid_mm;
        url = image.url;
      }
    });

    setGridDisplaySize(_gridSizeInt);
    return url;
  };

  const SubmitAno = async () => {
    console.log(annotations);

    if (!record.image) {
      addToast("Invalid grid type", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 8000,
      });
    } else {
      setIsLoading(true);
      const _annoData: object[] = [];
      var postData: boolean = true;

      annotations.forEach((anno) => {
        if (anno.superClass === "") {
          addToast("Please add a superclass category", {
            appearance: "warning",
            autoDismiss: true,
            autoDismissTimeout: 6000,
          });
          postData = false;
        }

        _annoData.push({
          super_class: anno.superClass,
          sub_class: anno.subClass,
          diagnosis: anno.diagnosis,
          priority: anno.priority,
        });
      });

      if (postData) {
        const result = await fetch(
          `${lablerConfig.host}/api/v1/record/annotation/create/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + (await sessionStorage.getItem("token")),
            },
            mode: "cors",
            body: JSON.stringify({
              record_id: record.id,
              annotations: _annoData,
            }),
          }
        )
          .then(async (response) => await response.json())
          .catch((err) =>
            console.error("Failed to post annotation.. error: " + err)
          );
        if (
          result.detail ===
          ("Invalid Token" || "User is not active" || "The Token is expired")
        )
          navigate("/");
        if (!result.error) {
          addToast("Submitted annotation successfully", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: winWidth <= 1000 ? 2500 : 4000,
          });
          GetRecord();
        } else {
          addToast(
            "Annotation submission failed (see console for more information)",
            {
              appearance: "error",
              autoDismiss: true,
              autoDismissTimeout: 8000,
            }
          );
          console.error(result);
        }
      }
      setIsLoading(false);
      console.log("setIsLoadingDONE");
    }
  };

  const HandleSetGridDisplaySize = (e) => {
    e.preventDefault();
    setGridDisplaySize(e.target.value);
    var newRecord = record;
    newRecord.image = HandleGridSize(newRecord, parseInt(e.target.value));
    setRecord(newRecord);
  };

  const ChangeGridSize = (i) => {
    setGridSize(gridOptions[i].toLowerCase().replace("mm", ""));
  };

  const NewAnnotation = () => {
    const newAnno = new AnnotationData();
    newAnno.priority = annotations.length + 1;
    setAnnotations([...annotations, newAnno]);
  };

  const ChangePriority = (oldPriority, newPriority) => {
    if (oldPriority === newPriority) return;
    const newAnnoList: AnnotationData[] = [];
    var minus = 0;
    var plus = 0;

    for (var i: number = 0; i < annotations.length; ++i) {
      if (i + 1 === newPriority && plus === 0 && minus === 0) {
        console.log("New");
        let oldAnno: AnnotationData = Object.assign(
          Object.create(Object.getPrototypeOf(annotations[oldPriority - 1])),
          annotations[oldPriority - 1]
        );
        oldAnno.priority = newPriority;
        newAnnoList.push(oldAnno);
        minus = 1;
      } else if (i + 1 === newPriority && plus === 1) {
        console.log("NewPlus");
        annotations[oldPriority - 1].priority = newPriority;
        newAnnoList.push(annotations[oldPriority - 1]);
        plus = 2;
        minus = 2;
      } else if (i + 1 === oldPriority && minus === 1) {
        console.log("OldMin");
        annotations[i - 1].priority = oldPriority;
        newAnnoList.push(annotations[i - 1]);
        plus = 2;
        minus = 2;
      } else if (i + 1 === oldPriority && minus === 0 && plus === 0) {
        console.log("old");
        annotations[i + 1].priority = i + 1;
        console.log(annotations[i + 1]);
        newAnnoList.push(annotations[i + 1]);
        plus = 1;
      } else if (minus === 1 || plus === 1) {
        console.log("mp");
        annotations[i - minus + plus].priority = i + 1; // fix here
        newAnnoList.push(annotations[i - minus + plus]);
      } else {
        console.log("nor");
        newAnnoList.push(annotations[i]);
      }
    }

    setAnnotations(newAnnoList);
  };

  const UpdateValues = async (
    annoIndex: number,
    sup: string,
    sub: string,
    diag: string,
    indexes: number[],
    customAno: boolean
  ) => {
    const updatedValues: AnnotationData[] = [];

    annotations.forEach((anno, i) => {
      if (i === annoIndex) {
        anno.superClass = sup;
        anno.subClass = sub;
        anno.diagnosis = diag;
        anno.indexes = indexes;
        anno.customAno = customAno;
        updatedValues.push(anno);
      } else {
        updatedValues.push(anno);
      }
    });

    setAnnotations(updatedValues);
  };

  const DeleteAnno = (i) => {
    const newAnnoArr: AnnotationData[] = [];

    annotations.forEach((anno, index) => {
      if (i !== index) {
        if (i < index) {
          anno.priority--;
        }
        newAnnoArr.push(anno);
      }
    });

    setAnnotations(newAnnoArr);
    // setAnnotations([...annotations.splice(i, 1)]);
  };

  return (
    <Box className="AP-mainCon">
      <Box className="AP-headerCon">
        <FormControl>
          <FormLabel className="AP-formLabel">Grid type</FormLabel>
          <RadioGroup
            row
            value={gridDisplaySize}
            onChange={HandleSetGridDisplaySize}
          >
            {record?.grid_mm_available?.includes(25) && (
              <FormControlLabel
                value="25"
                control={<Radio />}
                label={<p className="AP-formControlLabel">25mm</p>}
              />
            )}
            {record?.grid_mm_available?.includes(50) && (
              <FormControlLabel
                value="50"
                control={<Radio />}
                label={<p className="AP-formControlLabel">50mm</p>}
              />
            )}
          </RadioGroup>
        </FormControl>
        <Box className="AP-btnsCon">
          <Box className="AP-getRecordCon">
            <Button
              className="AP-getRecordBtn"
              variant="outlined"
              onClick={() => GetRecord()}
            >
              Load New Record
            </Button>
            <DropDownMenu
              className="AP-ddmGridSize"
              label="Grid"
              currentElement={
                gridSize.charAt(0).toUpperCase() + gridSize.slice(1)
              }
              elements={gridOptions}
              replace="mm"
              passedFunction={ChangeGridSize}
              smallscreen={winWidth <= 1000 ? "true" : "false"}
              hasvalue="true"
              emptyFirst={false}
              variant="outlined"
              size="small"
              selectStyle="AP-ddmSelectGridSize"
            />
          </Box>
          {!isLoading ? (
            <Button
              className="AP-submitAnoBtn"
              variant="contained"
              type="submit"
              onClick={SubmitAno}
            >
              Submit annotation
            </Button>
          ) : (
            <CircularProgress className="AP-circularProgressSubmit" size={30} />
          )}
        </Box>
        {record && !isLoading ? (
          <Box className="AP-patientInfoCon">
            <img
              className="AP-avatarImg"
              src={require(`../../assets/images/avatars/${
                record.data.sex === "M" ? "maleAva" : "femaleAva"
              }.png`)}
              alt="PatientAvatar"
            />
            <Box className="AP-patientInfo">
              <Box className="AP-patientInfoTextColumn">
                <p className="AP-patientInfoText">
                  Sex:{" "}
                  {(record.data.sex === "M" ? "male" : "female") ||
                    undefinedText}
                </p>
                <p className="AP-patientInfoText">
                  Age: {record.data.age || undefinedText}
                </p>
              </Box>
              <Box className="AP-patientInfoTextColumn">
                <p className="AP-patientInfoText">
                  Ethnicity: {record.data.ethnicity || undefinedText}
                </p>
                <p className="AP-patientInfoText">
                  BMI: {record.data.BMI || undefinedText}
                </p>
              </Box>
              <p className="AP-patientInfoText">
                Additional info: {record.data.additional_info || undefinedText}
              </p>
            </Box>
          </Box>
        ) : (
          <>
            {isLoading && (
              <CircularProgress className="AP-circularProgress" size={30} />
            )}
          </>
        )}
      </Box>
      {record && (
        <Box className="AP-bottomCon">
          <form className="AP-anoCon" method="GET" onSubmit={SubmitAno}>
            {!isLoading &&
              annotations.map((annotation, i) => {
                return (
                  <Annotation
                    className="AP-anno"
                    key={"Annotation_" + i}
                    winWidth={winWidth}
                    i={i}
                    updateValues={UpdateValues}
                    changePriority={ChangePriority}
                    annotation={annotation}
                    annotations={annotations}
                    deleteAnno={DeleteAnno}
                    priorityElements={Array.from(
                      { length: annotations.length },
                      (_, i) => i + 1
                    )}
                  />
                );
              })}
          </form>
          <Box>
            <Button
              className="AP-newAnnoBtn"
              variant="text"
              startIcon={<AddIcon />}
              onClick={NewAnnotation}
            >
              New Annotation
            </Button>
          </Box>
          {!isLoading ? (
            <Box className="AP-graphsPanel">
              {record.image ? (
                <TransformWrapper
                  initialScale={1}
                  panning={{ velocityDisabled: true }}
                >
                  {({ resetTransform }) => (
                    <TransformComponent>
                      <img
                        className="AP-graphsImg"
                        src={record.image}
                        alt="Graphs"
                      />
                    </TransformComponent>
                  )}
                </TransformWrapper>
              ) : (
                <p className="AP-gridNotAvailableText AP-patientInfoText">
                  This grid type is not available!
                </p>
              )}
              <Box className="AP-dbTextCon">
                <img
                  className="AP-dbIcon"
                  src={require("../../assets/images/icons/DB-icon.png")}
                  alt="DB-icon"
                />
                <p className="AP-patientInfoText">{record.origin_name}</p>
                <NumbersIcon className="AP-recordIdIcon" />
                <p className="AP-patientInfoText">{record.id}</p>
              </Box>
            </Box>
          ) : (
            <>
              {isLoading && (
                <CircularProgress className="AP-circularProgress" size={50} />
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AnnotationPage;
